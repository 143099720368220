.image-tracking-table {
    tr:nth-child(even) {
        background: #eeeeee !important;
    }

    tr:hover {
        background: #d6d6d6 !important;
    }

    a:nth-child(even) {
        background: #eeeeee !important;
    }

    a:hover {
        background: #d6d6d6 !important;
    }
}

.labelledIcon {
  padding: 8px 10px 10px 15px;
  align-items: 'right'
}

.clickable {
  cursor: pointer;
}
