.publishingPage {
  margin: 8px;
}

.publishingDetails {
  margin: 30px 0px 0px 0px !important;
  display: flex;
  flex-direction: row;
}

.publishProjectDate {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.project-name {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0px;
}

.project-description {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.projectInfo {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.umbrellaToggle {
  padding-bottom: 0px !important;
  padding-top: 5px !important;
}

.C-GridContainer.--spacing-normal.category-filter {
  margin-top: 10px;
}

.categorySelect {
  padding-top: 5px !important;
}

.C-Toggle__Input:checked+.C-Toggle__Slider {
  background: var(--canvas-background-color--interactive) !important;
  border: 1px solid var(--canvas-background-color--interactive) !important;
}

.closeUmbrellasDrawer {
  display: flex;
  justify-content: space-between;
}

.closeDrawerButton {
  cursor: pointer;
}

.offerList {
  border-radius: 8px;
  margin-top: 10px;
  background-color: #F7F7F7;
  padding: 12px;
  flex: 1;
  min-width: 375px;
  height: fit-content;
}

.storiesAndOffers {
  background-color: #F7F7F7;
  padding: 12px;
}

.media-units {
  padding: 0px 12px;
  flex: 2;
  height: fit-content;
}

.search-input {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.publishing-drag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10px;
  margin: 10px 0px 10px 0px !important;
  padding: 10px 10px 15px 10px;
  border: 2px dashed #ffffff;
  border-radius: 4px;
}

.publishing-drag-edit {
  border: none !important;
}

.ellipsis-dropdown {
  width: 167px;
  right: -0px !important;
  /* margin: 0px !important; */
  padding: 0px !important;
  top: 36px;
}

.insideStyle {
  border: 2px solid #b9d9ff;
  border-style: dashed;
}

.project-info {
  background-color: #F7F7F7;
  padding-left: 12px;
  padding-right: 12px;
}

.publishing-note{
  padding: 5px;
  padding-bottom: 20px;
}