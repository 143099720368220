.projectTableExecution {
    max-width: 900px;
    margin: 20px 40px;
}

.tableHeader {
    background-color: none;
}

.tableRow {
    background-color: rgb(255, 255, 255) !important;
    margin-bottom: 5px;
    max-width: calc(100% - 20px);
 }

.selectedRow {
    border: 1px solid var(--functional-border-border-color-interactive, #3D70D6);
    background-color: var(--functional-background-background-color-interactive, #3D70D6) !important;
    margin-bottom: 5px;
    max-width: calc(100% - 20px);
    color: #ffffff
}

.projectTD {
    font-size: 14px !important;
    padding: 12px;
}

.publishing-select-boxes {
    width: 100%;
}

.projectTH {
    font-size: 14px !important;
}

.modalProjectMessage {
    padding-left: 12px;
  }

  td.C-TableData.--xs-size-4.projectTD {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

td.C-TableData.--xs-size-3.projectTD {
   line-height: 1 !important;
}


.mktgTableExecution {
    .tableRow, .selectedRow{
        height: 40px
    }

    padding-top: 10px;
    margin: 20px 10px 0px 10px;

    thead {
        background-color: transparent;
    }

    span.C-TableHeader__innerContent {
     font-size: 12px;
     color: #333;
    }
}

label.C-Input--type-label {
    font-weight: 400 !important;
}

span.C-Chip__children {
    max-width: 100%;
    min-width: 58px;
}

.chipContainer .C-Chip.--dense  {
        height: 16px
}


.pyramidSpinner {
    text-align: center;
}
