.main-container {
  background-color: #efefef;
  overflow: auto;
}

.medium-padding {
  padding: 8px;
}

.alternate-color {
  background-color: #d9d9d9;
}

.table-header {
  font-size: 1.3em !important;
}

.column-text-heading {
  display: flex !important;
  justify-content: center !important;
  font-weight: bold;
  text-align: center !important;
  margin: auto;
}



.radio-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.radio-checked-color {
  background: #b85300;
  border: 1px solid #b85300;
}

.radio-checked-color-readonly {
  background: #919191;
  border: 1px solid #919191;
}

.radio-unchecked-color {
  background-color: white;
  border: 1px solid black;
}

.radio-unchecked-color-readonly {
  background-color: white;
  border: 1px solid #919191;
}

.radio-checked {
  position: relative;
  width: 21px;
  min-width: 21px;
  height: 21px;
  border-radius: 24px;
  transition: border 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.radio-checked::after {
  background: #fff;
  border-radius: 24px;
  content: '';
  height: 9px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 9px;
}

.radio-unchecked {
  cursor: pointer;
  width: 21px;
  min-width: 21px;
  height: 21px;
  border-radius: 24px;
  transition: border 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.not-allowed {
  cursor: not-allowed;
}

.bottom-underline {
  border-top: 3px solid black;
  border-bottom: 1px solid black;
}

.column-heading {
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  margin: auto;
}

.grid-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding: 7px;
  white-space: nowrap;
}

.column-width {
  width: 60px;
}

.center-horizontally {
  justify-content: center;
}

.center-vertically {
  align-items: center;
}

.outcome-style {
  background-color: #e8e8e8;
  border-top: 1px solid;
  font-weight: bold;
  border-bottom: 3px double !important;
}

.story-style {
  background-color: #e8e8e8;
  padding-left: 10px !important;
  border-bottom: 1px solid !important;
}

.story-lone-style {
  background-color: #e8e8e8;
  border-top: 1px solid;
  padding-left: 10px !important;
  border-bottom: 1px solid !important;
}

.umbrella-style {
  padding-left: 35px !important;

  .umrella-name-chip{

    span.C-Chip__children {
      max-width: 100%;
      min-width: 74px;
    }
  }
}

.radio-style {
  display: flex !important;
  justify-content: center !important;
  text-align: end !important;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.table-sticky {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
}
