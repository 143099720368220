.page-view {
  &-filter {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &-container {
    height: 85%;
    width: 85%;
    padding: 15px;
    overflow: auto;
    margin: auto;
    background-color: #ffffff;
  }
  &-close {
    float: right;
  }
  &-download {
    float: right;
    margin-right: 5%;
  }

  &-story {
    display: flex;
    flex-wrap: wrap;
  }

  &-cut {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
    margin: 10px;
    padding: 10px;
    border-radius: 9px;

    &--count {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      text-align: center;
      border: 1px solid #000000;
    }
  }

  &-item {
    max-width: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
  }
  &-assethub {
    position: relative;
    top: -25px;
    margin-bottom: 0;
  }
}

.loading-modal {
  z-index: 11 !important;
}

.weekly-ad-merch-view-container {
  max-height: calc(100vh - 157px);
  overflow: auto;
}
