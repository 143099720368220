.stickybar-container-planning {
    position: sticky;
    top: 56px;
    margin-top: 0 !important;
    z-index: 3;
    background-color: #d9d9d9;
    display: grid;
    grid-gap: 8px;
    grid-column: 1fr 2fr 1fr 1fr 1fr;
    padding: 0 30px;

    .gray-btn, .gray-btn:hover {
        background-color: #3838a3;
        border: none !important;
        color: white;
        span {
            margin-left: 10px;
        }
        margin-top: 17px;
    }

    #sticky-search {
        width: 300px;
    }
}