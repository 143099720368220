.timeline-item {
  width: 100%;
  flex-direction: column;

  &--date {
    margin-top: 12px;
  }

  &--content {
    display: flex;
  }

  &--title {
    text-transform: uppercase;
  }

  &--value {
    font-weight: 100;
    margin-right: 5px;
    white-space: pre-wrap !important;
  }
}
.tableRow {
  background-color: rgb(255, 255, 255) !important;
  margin-bottom: 7px;
  max-width: calc(100% - 20px);
  color: #000000;
  border: 1px solid var(--functional-border-border-color-interactive, #909192);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.rotate-clockwise-45 {
  transform: rotate(00deg);
  transition: transform 0.2s;
}

.title-icon{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

}

.media-unit-expand-icon{
  margin-top: -2px;
}

.timeline-item--date{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:10px;
  border-top: 1px solid #b3b6bc !important;
  padding-top: 10px;
  padding-bottom: '10px';
  padding-top:  '10px';
}

.offer-details-container{
  padding-left: 22px;
  padding-top: 10px;
}

.result-container{
  padding-left: 22px;
  padding-top: 10px;
}

.rotate-counter-clockwise-45 {
  transform: rotate(-90deg);
  transition: transform 0.2s;
}

.flex-center{
  display: flex;
  align-items: center;
}

.selectedRow {
  border: 1px solid var(--functional-border-border-color-interactive, #3D70D6);
  background-color: var(--functional-background-background-color-interactive, #3D70D6) !important;
  margin-bottom: 5px;
  max-width: calc(100% - 20px);
  color: #ffffff
}
.table-container{
  margin: 10px;
}

.notes_info {
  font-size: 11px;
  color: #888888;
}

.timeline-item--content{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.font-header{
  font-size: 16px;
}

.offer-list-data{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.table-row-field-changed-Not{
  background-color: #ffffff;
  border-top: 1px solid #b3b6bc !important;
  font-size: 14px;
  font-weight: 400;
  
}
.pyramid-and-division-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.timeline-item--value:hover {
  background: #d6d6d6 !important;
}
tr:hover .main-container {
  background: #d6d6d6 !important;
}
.timeline-approved {
  position: absolute;
  right: 0;
  top: 6px;
  width: unset;
}

.timeline-spinner {
  position: absolute;
  right: 8px;
  top: 12px;
}

.datepicker-icon div span {
  top: 6px;
}

.autocomplete-alignment {
  label,
  p {
    margin-bottom: 0px;
  }
}

.C-Timeline__Item__Bullet {
  background: #008300;
  border-color: #008300;
  border-radius: 24px;
  border-radius: var(--canvas-border-radius--round);
  height: 16px;
  margin: 17px 12px 12px 0;
  min-width: 16px;
  transition: canvas-transition(background border-color);
  /* z-index: 2; */
  z-index: var(--canvas-z-index--element);
}

.C-Timeline__Item__Content {
  margin-top: 0px;
  width: 100%;
}

.reviewed-checkbox {
  display: flex;
  margin-left: 15px;
  margin-top: 5px;
}

.icons {
  padding: 10px;
  text-align: left;
  position: relative;
}

.main-container {
  background-color: inherit;
}

.notes_info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.C-MenuButton {
  padding-left: 7px;
  padding-right: 7px;
}

.edit-textbox {
  padding: 7px;
  border-radius: 8px;
  margin-bottom: 6px;
  margin-right: 5px;
  border: 1px solid var(--functional-border-border-color-contrast, #d3d5d8);
}

.C-EditButton {
  padding: 1px 7px;
}

.marginLeft {
  margin-left: 5px !important;
}

.paddingBottom0px {
  padding-bottom: 0px !important;
}

.font20 {
  font-size: 20px;
}

.font14 {
  font-size: 14px;
}

.checkbox-parent {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.mktg-approve-all {
  display: flex;
  margin-top: 5px;
}

.offer-name {
  font-size: 14px;
}
.deleted-text-old-value-text {
  font-family: 'Helvetica for Target';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-decoration-line: line-through;
  color: var(--canvas-font-color--error);
  margin-bottom: 5px;
}

.deleted-text-old-value-heading {
  font-family: 'Helvetica for Target';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  text-decoration-line: line-through;
  color: var(--canvas-font-color--error);
}

.added-text-new-value-text{
  font-family: 'Helvetica for Target';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color:var(--canvas-font-color--success);
  margin-bottom: 5px;
}

.added-text-new-value-heading {
  font-family: 'Helvetica for Target';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  color:var(--canvas-font-color--success);
}

.old_new_margin {
  margin-bottom: 5px;
}

.sortContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filters-hidden {
  width: 100% !important;
}

.filters-showing {
  width: 83% !important;
}
.table-row-field-changed{
  background-color: #eeeeee;
}