.summaryContainer {
    margin: 20px;
    padding: 15px;
}

.projectDate {
    padding-bottom: 20px;
}

.offerName {
    padding-left: 20px;
}

.padding-left-15 {
    padding-left: 15px;
}
