.umbrella-container {
    background-color: lightgray;
    padding: 15px;
    border-radius: 8px;
}
.offer-container {
    background-color: rgb(242, 243, 243);
    padding: 15px;
    border-radius: 8px;
    border: .5px solid gray;
    margin-top: 5px;
    padding-left: 22px;
    padding-top: 10px;
    
}
.wa-summary-item {
  
    &--value {
      font-weight: 100;
      text-transform: initial;
    }
    
}
  .item-container {
    padding: 5px;
    grid-gap: 5px;
    width: 100%;
    border: .5px solid gray;
    background-color: white;
  }
  .marketing-story-title {
    border-bottom: 1px solid gray;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px;
  }

  .no-items-found {
    margin-top: 50px;
    text-align: center;
    font-size: medium;
  }
  .story-title {
    margin-bottom: 20px;
  }
  
  .project-title {
    margin-top: 20px;
    margin-right: 10px;
  }

  .card {
    display: flex;
    align-items: flex-start;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: calc(49% - .25px);
    margin: 2px;
    background-color: lightgray;
}

.card-image {
    width: 200px;
    height: 200px; 
}

.card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.storyHeader {
  .C-ExpandableSection__TopRow {
    background-color: rgb(232, 238, 252);
  }
}

.umbrella-detail-key{
  font-weight: 500;
}

.textButton, .textButton:hover {
  color: white;
  background-color: #3838a3;
}

.centerContent {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.summaryHeader {
  .C-Breadcrumbs__Item:hover{
    text-decoration: none;
    cursor: default;
  }
}

.umbrellaDetail {
  background: rgb(242, 243, 243);
  margin: 10px;
  padding: 10px;
  width: 100%,
}

.offerListContainer {
  height: calc(100vh - 90px);
  overflow: auto;
}

.contentEnd {
  height: 30px;
  width: 100%;
}

.storyList {
  margin: 10px 5px;
}

.deactivated {
  text-decoration: line-through;
  color: rgb(43, 45, 49, 0.5);
}