.publishOffer {
  margin: 0 auto !important;
  padding: 0px;
  width: 100% !important;

  .C-GridItem {
    padding: 6px 6px 6px 12px !important;
    color: #666666;

    .inlineInput {
      margin: -4px 0 -4px -12px !important;
    }
  }

  .pricePatch {
    background-color: #000 !important;
    color: #fff !important;
    margin-top: 10px !important;
  }

  .offerExpand {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border: 0px solid lightgray !important;
  }
}

.headerRow {
  color: #888 !important;
  font-weight: 700;
  font-size: 12px;
  margin-top: 0px !important;
  padding-left: 5px !important;
}

.headerButtonText {
  font-size: 12px;
  color: #3e72db !important;
  font-weight: 400;
}

.expandButton {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  float: right;
  margin-top: 2px;
  span {
    margin-left: 10px;
  }
}

.offerContainer {
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  grid-gap: 10px;
  align-content: flex-start;
  padding: 15px 0px 15px 0px;
  overflow-y: scroll;
  overscroll-behavior: contain;
  min-height: 40px;
  border-top: 1px solid #858585;
}

.offer-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeholder-offer {
  background-color: #ffeef9 !important;
}

.deactivated-offer {
  background-color: rgb(228, 228, 228) !important;
}

.adPatch-offer {
  border: 2px solid #ffe1b0 !important;
}

.pricePatch-offer {
  border: 2px solid #f2c9ff !important;
}

.empty-offer {
  padding: 12px;
}

.deactivated-offer-header {
  color: #999 !important;
  font-size: 12px;
}

.offer-select {
  height: auto !important;
}

.offer-card-padding {
  padding: 5px !important;
}

.offerLink {
  color: #3e72db;
  font-weight: 400;
}

.offerLink:hover {
  text-decoration: underline;
}

.C-FormField__Label__saleHandleRequired {
  color: #b85300;
  font-size: 10px;
  /* line-height: 7px; */
}

.invalidDates {
  color: #b0270d !important;
}

.boldDates {
  font-weight: bold;
}

.ivyTotalItems {
  color: #000;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  padding-left: 5px;
  font-weight: 300;
}

.offerUmbrellaHeader {
  padding: 4px 12px 4px 12px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 12px;
  overflow: hidden;
  white-space: nowrap;
}

.simpleBucketItem {
  background-color: white;
  padding: 8px !important;
  margin: 0 0 12px 0 !important;
  border-radius: 4px;
  border: 0px solid #b3bcd4;
}

.publishing_offer_list {
  background: #ffffff !important;
  padding: 12px;
  margin-top: 10px;
  max-height: calc(80vh - 162px);
  overflow-y: scroll;
}

.publishing-offer-holder {
  padding: 12px 0 0 0 !important;
}

.umb-name-spacer {
  padding-left: 10px !important;
}
