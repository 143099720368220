.logo {
  width: 100%;
  padding: 0;
  margin-bottom: 10%;
}

.unauthorized {
  width: 499px;
  margin: 75px 25% 75px 25%;
  border: #cc0000 solid 4px;
  padding: 5%;
}
