.umbrellaCard {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 6px;
  border: 2px ridge #b9d9ff;
  width: 100% !important;
  font-size: 12px;
  padding: 3px 6px 3px 6px;
  box-shadow: 3px 3px 1px rgba(205, 205, 205, 0.75);
  position: relative;

  .C-Chip {
    margin: 0 0 0 2px !important;
    height: 18px !important;
  }

  .C-Chip__children {
    font-size: 10px !important;
    padding: 0px 4px !important;
    width: 55px !important;
  }

  .chips {
    text-align: right;
  }

  .C-GridItem {
    padding: 2px !important;
  }
}

.hide {
  transition: 0.01s;
  opacity: 0.25;
}

.numberOfUmbrellas:active {
  animation: drag_style 0.1s;
}

.umbrellaCount {
  display: flex !important;
  z-index: 12 !important;
  font-size: 14px;
  color: #1c81fd;
  font-weight: normal;
}

.selectedUmbrellaCard {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 6px;
  border: 3px solid #1c81fd;
  width: 100% !important;
  font-size: 12px;
  padding: 3px 6px 3px 6px;
  box-shadow: 3px 3px 1px rgba(205, 205, 205, 0.75);
  position: relative;

  .C-Chip {
    margin: 0 0 0 2px !important;
    height: 18px !important;
  }

  .C-Chip__children {
    font-size: 10px !important;
    padding: 0px 4px !important;
    width: 55px !important;
  }

  .chips {
    text-align: right;
  }

  .C-GridItem {
    padding: 2px !important;
  }
}

.umbrellaName:hover {
  text-decoration: underline;
  color: #313199;
}

.header,
.basicInfo {
  margin: 1px !important;
  width: 100% !important;
  padding: 0 0 1px 0 !important;
}

.expandedUmbrellaSection {
  background-color: rgb(236, 245, 255);
}

.unlinkUmbrellaButton:hover .fa-icon {
  color: rgb(206, 206, 206);
}

.unlinkUmbrellaButton {
  text-align: right;
}

.umbrellaName {
  display: block;
  width: 100%;
}

.deactivatedName,
.invalidDates {
  color: #b0270d;
  text-decoration: line-through #b0270d;
}
.invalidDatesText {
  color: #b0270d;
  text-decoration: none !important;
}

.invalidDatesPublishing {
  color: #ffffff;
  text-decoration: line-through #ffffff;
}
.invalidDatesTextPublishing {
  color: #ffffff;
  text-decoration: none !important;
}

.deactivatedLink {
  text-decoration: line-through #b0270d;
}

.deactivatedLink:hover {
  color: #313199;
  text-decoration: line-through #313199;
}

.linked {
  color: darkorange;
  padding-right: 5px;
}

.umbrella-copy-btn {
  margin-left: 8px;
}

.umrella-name-chip {
  padding-right: 2px;
}

.fa-duotone {
  padding-right: 4px;
}

.umbrella-item-title:hover {
  text-decoration: underline;
  color: #3e72db;
}

.copy-btn {
  margin-left: 8px;
}

.copy-btn:hover {
  color: #3e72db;
}
