.pyramidHeader {
  padding: 4px 12px 4px 12px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0px;
  overflow: hidden;
  width: '100%';
}
.basicBoxStyle {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  margin: 4px;
}
.late_offer_background {
  background-color: #ff0;
}

.deactivated {
  text-decoration: line-through;
  color: rgb(43, 45, 49, 0.5);
}
.offer-pages {
  margin-top: 2px;
  text-align: right;
  display: flex;
  gap: 5px;
  float: right;
  flex-wrap: wrap;
  overflow-x: auto;
  white-space: nowrap;
}

.offer-page-box {
  font-size: 10px;
  background-color: rgb(232, 238, 252);
  border-radius: 4px;
  color: #a7a7a7;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 20px;
  font-weight: bold;
}

.offer-page-box-empty {
  font-size: 10px;
  background-color: rgb(232, 238, 252);
  border-radius: 4px;
  color: #a7a7a7;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 20px;
  font-weight: bold;
  margin-top: 20px;
  background-color: transparent;
}
