.pageContainer {
  display: flex;
  height: 100%;
  overflow: auto;
  padding: 15px;
  flex-wrap: wrap;
}
.textStyles {
  color: #666;
  margin: 2px;
  padding: 2px;
}
.draggablePageContainer {
  padding: 10px;
}

.fabContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 69/80;
}
.inline-radio-options {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.color-picker-modal {
  max-width: fit-content;
  margin: 0 auto;
}
.fab-card {
  aspect-ratio: 25/34;
}
.page-label {
  align-items: center;
  background-color: rgb(232, 238, 252);
  border-radius: 4px;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 5px;
  width: 30px;
}
.label-text {
  color: #a7a7a7;
}
.icon-color {
  color: #3838a3;
  font-size: 15px;
}

.deactivated {
  text-decoration: line-through;
  color: rgb(43, 45, 49, 0.5);
}
.group-icon-color {
  color: #3838a3;
  font-size: 15px;
  margin-left: 4px;

  &:hover,
  &:active {
    color: white;
  }
}

#book-captains-button {
  color: #3838a3;
  border-color: #3838a3;
  margin-top: 17px;
  margin-left: 10px;
  &:hover,
  &:active {
    color: white;
    background: #3838a3;

    .group-icon-color {
      color: white;
    }
  }

  &:disabled {
    color: #a3a3a3;
    border-color: #a3a3a3;
    background: #f0f0f0;

    .group-icon-color {
      color: #a3a3a3;
    }
  }
}
.avatar-book-captains {
  margin-top: 17px;
  cursor: default;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
#color-palette-button {
  color: white;
  background: #3838a3;
  border-color: #3838a3;
  margin-top: 17px;
  margin-left: 10px;
  .group-icon-color {
    color: white;
  }
  &:disabled {
    color: #a3a3a3;
    border-color: #a3a3a3;
    background: #f0f0f0;

    .group-icon-color {
      color: #a3a3a3;
    }
  }
}
