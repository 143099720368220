.color-palette-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  min-height: 400px;
  max-height: 400px;
}
.image-container {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.color-palette-image {
  max-height: 300px;
  border: 1px solid black;
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
  max-width: 100%;
}
.image-container img {
  margin: 0 auto;
}
