.story {
  margin: 10px 0px 10px 0px;
  padding: 10px 15px;
  width: 100%;


 
  border-radius: 10px;

  .storyName {
    margin-bottom: 12px;
  }

  .trashIcon {
    text-align: right;
  }

  .umbrellaBucket {
    display: flex;
    gap: 6px;
    align-content: flex-start;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: auto !important;
    min-height: 10px;
    margin: 10px 0px 10px 0px !important;
    padding: 10px 10px 15px 10px;
    border-top: 2px solid rgb(228, 228, 228);
    border-bottom: 2px solid rgb(228, 228, 228);
    border-radius: 10px;
  }
  
  .insideStyle {
    box-shadow: 0px 0px 15px 10px #b9d9ff;
  }

  .storyUmbrellaDiv {
    width: 100%;
  }
}

.modalStoryMessage {
  padding-left: 18px;
}

.C-Button.dkgrey-btn-sort {
  background-color: #666666 !important;
  border: none !important;
  color: white !important;
  font-size: 12px;
}

.storyContainer{
  background-color: #666666;
}