.form-container {
  padding-left: 25px;
}

.market {
  padding: 0px 30px 25px 0px
}

.indd-page {
  padding: 0px 30px 25px 0px;
  width: 12%
}

.button-group {
  padding: 20px 0px 20px 0px
}

.image {
  width: 290px;
  padding: 20px 20px 5px 20px
}

.image:hover {
  cursor: pointer;
}

.image-modal { 
  .C-Modal__container {
      max-width: 1250px; 
  }

  .C-Modal__scrollContainer {
      overflow: auto;
  }
}

.C-FormRadio__Option { 
  display: inline-flex; 
  margin-right: 1em; 
}

.full-image {
  padding-top: 35px;
  margin: auto;
  display: block;
}

.image-info {
  width: 290px;
  padding: 10px 20px 0px 20px;
  text-align: center;
}

.page-number {
  font-size: 16px;
  margin: 15px;
}

.edit-button {
  margin: 15px;
}

.trash-button {
  margin: 15px;
}

.edit-modal {
  .C-Modal__scrollContainer {
    min-height: 600px;
  }
}