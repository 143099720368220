.projectDetails {
  margin: 40px 20px !important;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.umbrellas-visible {
  grid-template-columns: 38% 62%;
}

.projectDates {
  text-align: right;
}

.pyramids,
.umbrellas {
  width: 98%;
  padding: 15px;
  border-radius: 10px;
}

.umbrellas {
  padding-right: 10px;
  background-color: white;
  height: fit-content;
  margin-top: 10px;

  h2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.pyramids {
  padding-top: 0;
  margin-top: 0;
}

.pyramidsContainer {
  height: 100vh;
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 1200px;
}

.umbrellaContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-content: flex-start;
  max-width: 600px;
  height: 75vh;
  border-top: 2px solid rgb(228, 228, 228);
  border-bottom: 2px solid rgb(228, 228, 228);
  border-radius: 10px;
  max-height: fit-content;
  min-height: 40px;
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.pyramidText {
  color: #fff;
}

.strategicIntent {
  padding: 4px;
  margin: 14px 0 4px 0 !important;
}

.unlinkUmbrellaTab {
  text-transform: capitalize !important;
  background-color: #3d70d6 !important;
  color: white !important;
}
.activeTab {
  background-color: #2851a3 !important;
  color: white !important;
}

.dkgrey-bt-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.C-Button.dkgrey-btn-sort {
  background-color: #666666 !important;
  border: none !important;
  color: white !important;
  font-size: 12px;
}

.border-left {
  border-radius: 5px 0 0 5px !important;
}

.border-right {
  border-radius: 0 5px 5px 0 !important;
}

.sortbtns {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 20px 0 20px 0;
}

.stickyBar {
  background-color: red;
}

.projectDetailsHeaderContainer {
  position: sticky;
  top: 56px;
  background-color: #f0f0f0;
  z-index: 4;

  margin-top: 0 !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  padding: 15px 20px;
  border-top: 1px solid #b9b5b5;
  border-bottom: 1px solid #b9b5b5;
}

.projectDetailsHeader {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  font-weight: 700;
  .C-Toggle {
    margin: 0;
    width: fit-content;
  }
}

.umbrellaTabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap !important;
  margin: 40px 0 10px 0;
  gap: 0;
  display: grid !important;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  flex: 1 1 auto !important;
  box-sizing: border-box;
  padding: 0px !important;
}

.expand-button {
  background-color: white !important;
}