.rounded-container {
    border: 2px solid black; 
    border-radius: 10px; 
    padding: 14px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    background-color: #fff; 
    flex-wrap: nowrap;
  }
#editable-input{
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    align-items: center;

    &.editable-input-pyramid {
      padding-left: 10px;
      font-weight: bold;
      text-decoration: underline;
      color: #000000 !important;
    }
    &.editable-input-story {
      padding-left: 10px;
      font-weight: bold;
      color: #000000 !important;
    }
    &.editable-input-umbrella {
      padding-left: 10px;
      font-weight: normal;
      color: #525050 !important;
    }
    &.editable-input-offer {
      padding-left: 10px;
      color: #867979 !important;
      font-weight: lighter;
    }
    
  }
  #editable-input .C-Input:hover:not(.isDisabled){
    border: none;
  }
  .hoverBackground {
    background: #e8eefc !important;
  }
  
  .trash-button {
    color: #3838a3;
    font-size: 16px;
  }

  .editable-input-container{
    position: relative;
    align-items: center;
    display: flex;
    width: 100%;
  }

  .expand-collapse-button {
    top: 3px;
    margin-right: 2px;
  }
.label-pyramid {
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
}

.label-story {
  font-weight: bold;
  font-size: 14px;
}

.label-umbrella {
  color: #353232; 
  font-size: 14px;
}

.label-offer {
  color: #575454; 
  font-size: 14px;
  margin-left: 32px;
}

.deactivated {
  text-decoration: line-through;
  color: rgb(43, 45, 49, 0.5);
}