#datepicker {
    .C-Modal__container {
        min-height: 450px !important;
    }
}

.cancel {
    padding-top: 80px !important;
}

.copyWriter-Header {
    margin: 0 auto !important;
    padding: 5px 10px;
    width: 100% !important;
  
    .C-GridItem {
      padding: 6px 6px 2px 6px !important;
    }

    .C-FormField--Toggle {
        padding: 0px !important;
        color: #fff;
    }
    
}

