.expanded-offer {
  margin: 10px 0px 0px 0px !important;
  background: #f0f2f4 !important;

  .offer-info {
    min-width: 66% !important;
    padding: 15px !important;
  }

  .C-GridItem.--xs-size-8 {
    color: var(--functional-font-font-color-default, #2b2d31);
    text-align: start;
    font-family: Helvetica for Target;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .user-field {
    background-color: rgb(255, 255, 255) !important;
  }
}
.C-GridItem.--xs-size-3.hc-bg-grey05 {
  border-top-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
  display: flex;
  width: 163.5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--neutrals-black, #333);
  font-family: Helvetica for Target;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  align-self: stretch;
  border-bottom: 1px solid var(--neutrals-white, #f0f0f0) !important;
  background: var(--neutrals-grey-07, #f7f7f7) !important;

  .user-field {
    background-color: rgb(255, 255, 255) !important;
  }
}

.C-GridItem.--xs-size-4.hc-bg-grey05 {
  border-top-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
  display: flex;
  width: 163.5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--neutrals-black, #333);
  font-family: Helvetica for Target;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  align-self: stretch;
  border-bottom: 1px solid var(--neutrals-white, #f0f0f0) !important;
  background: var(--neutrals-grey-07, #f7f7f7) !important;
}
.item-bucket-container {
  background: var(--neutrals-grey-07, #ffffff) !important;
  border-radius: 8px;
  overflow: scroll;

  .C-GridItem {
    border: none !important;
  }
}

.offer {
  margin: 0 auto !important;
  width: 100% !important;
  border-radius: 8px !important;
  padding: 8px;

  .pricePatch {
    background-color: #000 !important;
    color: #fff !important;
    margin-top: 15px !important;
  }
}

.drag-handle {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.drag-handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.headerRow {
  color: #000 !important;
  font-weight: 500;
}

.headerButtonText {
  font-size: 12px;
  color: #3e72db !important;
  font-weight: 400;
}

.expandButton {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  float: right;
  margin-top: 2px;
  span {
    margin-left: 10px;
  }
}

.C-GridItem.--xs-size-3.hc-bg-grey05 {
  background-color: #e6e6e6 !important;
  border-radius: none;
}

.placeholder-offer {
  background-color: #ffeef9 !important;
}

.deactivated-offer {
  background-color: rgb(228, 228, 228) !important;
}

.adPatch-offer {
  border: 2px solid #ffe1b0 !important;
}

.pricePatch-offer {
  border: 2px solid #f2c9ff !important;
}

.empty-offer {
  padding: 12px;
}

.deactivated-offer-header {
  color: #999 !important;
  font-size: 12px;
}

.offer-select {
  height: auto !important;
}

.offer-card-padding {
  padding: 5px !important;
}

.offerName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.offerLink {
  color: #3e72db;
}

.offerLink:hover {
  text-decoration: underline;
}

.C-FormField__Label__saleHandleRequired {
  color: #b85300;
  font-size: 10px;
  /* line-height: 7px; */
}

.invalidDates {
  color: #b0270d !important;
}

.boldDates {
  font-weight: bold;
}

.ivyTotalItems {
  color: #000;
  display: inline-block;
  margin-right: 5px;
  padding-right: 5px;
  font-weight: 300;
  margin-bottom: 0px;
  margin-top: 3px;
}

.media-unit-offer {
  background-color: rgb(249, 249, 249);
  padding: 4px;
  margin: 6px;
  border-radius: 8px;
  border: 1px solid #b3bcd4;
}

.emailButton_showMore {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid var(--functional-border-border-color-contrast, #d3d5d8);
  background: var(
    --functional-background-background-color-ui,
    #fafcfd
  ) !important;
}
.emailButton_showLess {
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid var(--functional-border-border-color-contrast, #d3d5d8);
  background: var(
    --functional-background-background-color-ui,
    #fafcfd
  ) !important;
}

.expand-offer_showMore {
  margin-bottom: 5px !important;
  background: #f0f2f4 !important;
  color: #e6e7ea;
}

.expand-offer_showLess {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background: #f0f2f4 !important;
}

.featured-items-header {
  font-size: large !important;
  font-weight: bold !important;
}

.late_offer_background {
  background-color: #ff0 !important;
}

.centered-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.check-button {
  background-color: #3838a3 !important;
  color: #fff !important;
}

.weekly_ad_page_labels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40px;
}
