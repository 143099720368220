.stickybar-container-execution {
    position: sticky;
    top: 56px;
    margin-top: 0 !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    z-index: 3;
    background-color: #e6e6e6;
    display: grid;
    grid-gap: 0px;
    grid-column: 1fr 2fr 1fr 1fr 1fr;
    padding: 0;
    width: auto !important;

    #sticky-search {
        width: 300px;
    }
}

.gray-btn, .gray-btn:hover {
    background-color: #3838a3 !important;
    border: none !important;
    color: white !important;
    span {
        margin-left: 10px;
        margin-right: 10px;
    }
    margin-top: 17px;
}