.POP-table {
    tr:nth-child(even) {
        background: #eeeeee !important;
    }

    tr:hover {
        background: #d6d6d6 !important;
    }

    a:nth-child(even) {
        background: #eeeeee !important;
    }

    a:hover {
        background: #d6d6d6 !important;
    }

 
}

.POP-table .C-Button.--type-primary,
.POP-table .C-Button.--type-submit {
  background: var(--canvas-background-color--interactive) !important;
  border-color: var(--canvas-border-color--interactive);
  color: var(--canvas-global-color--white);
}

.product-list-table-header{
    background-color: transparent;
    span.C-TableHeader__innerContent {
        color:#333
    }
}

.product-list-table-row{
    display: flex;
    justify-content: space-around;
}

.weekly-ad-expandable-page {  
    background: #f7f7f7!important;
    .C-GridItem.--xs-size-1 {
        display: flex;
        justify-content: flex-end;
    }
}

.C-Card.--elevation-1.weekly-ad-expandable-page {
    margin-top: 10px;
    box-shadow: 0 0 0 1px rgb(51 51 51 / 4%), 0 1px 3px 0 rgb(51 51 51 / 16%) !important;
}

.C-ExpandableSection__Toggle {
    display: none !important;
}

.rotate-clockwise-45 {
    transform: rotate(00deg);
    transition: transform 0.2s;
  }
  
  .rotate-counter-clockwise-45 {
    transform: rotate(-90deg);
    transition: transform 0.2s;
  }