.image_modal {
padding: 20px;
}

.image_modal .C-Heading {
    padding-left: 25px;
    padding-top: 10px;
}

.C-FormField--Toggle {
    padding: 10px 0 20px 20px;
}

.imageGridContainer {
    align-content: flex-start;
    grid-gap: 3%;
}

.imageContainer {
    border: lightgrey 1px solid;
    border-radius: 3%;
    width: 22%;
    margin-bottom: 3%;
}

.noImageThumbnail {
    margin: 0 10%;
}

.imageInfo {
    padding: 15px;
}

.break {
    padding: 10px 0 0 0;
}

.image_check {
    padding: 5px 0;
}

.download {
    margin-top: 10px;
    height: 20px;
    float: right;
}

.noAssets {
    padding-left: 35px;
}

button {
    border: none;
    background-color: white;
}

