    textarea.C-FormField--Textarea.--grow:focus {
        width: 100% !important;
    }

    td.C-TableData.promo-message-header-container {
        padding: 17px 0px 0px 8px;
    }

    .deactivatedName {
    color: #b0270d;
    }

    .deactivatedLink {
    text-decoration: line-through #b0270d;
    }

    .execution-umbrellaCard {
        background-color: #fff !important;
        border-radius: 6px;
        border: 2px ridge #b9d9ff;
        width: 100% !important;
        font-size: 12px;
        padding: 3px 6px 3px 6px;
        box-shadow: 3px 3px 1px rgba(205, 205, 205, .75);
        position: relative;
        margin-bottom: 5px;
    }

    .invalidDates {
        color: #b0270d;
        text-decoration: line-through #b0270d;
      }
      .invalidDatesText {
        color:  #b0270d;
        text-decoration: none !important;
      }
      .umbrella_name_background {
        background-color: #FFFF00;
      }
