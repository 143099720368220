 .inlineInput {
    .C-FormField--Input, .C-FormField--Textarea {
        border: .5px solid #adadad;
        box-shadow: none !important;
        // width: 95% !important;
    }

    .C-FormField__FieldInfo {
        margin-top: 0px !important;
    }

    .C-FormField--Input::placeholder {
        color: #cc0000 !important;
        opacity: .5;
    }

    .C-FormField--Textarea {
        min-height: 14px;
        max-height: 100px;
        margin: 5px;
    }

    .C-FormField--Input:hover,
    .C-FormField--Input:focus,
    .C-FormField--Textarea:hover,
    .C-FormField--Textarea:focus {
        box-shadow: none !important;
        background-color: #ededed;
        // width: 95% !important;
    }
  }

.inlineStory {
    .C-FormField--Input, .C-FormField--Textarea {
        background-color:  rgb(255, 255, 255)
    }

    .C-FormField--Input, .C-FormField--Textarea::placeholder {
        color: #cc0000 !important;
        opacity: .5;
    }

    .C-FormField--Input:hover,
    .C-FormField--Input:focus,
    .C-FormField--Textarea:hover,
    .C-FormField--Textarea:focus {
        background-color: #ffffff;
    }
}

.readOnly .C-FormField--Input:hover,
.readOnly .C-FormField--Input:focus,
.readOnly .C-FormField--Textarea:hover,
.readOnly .C-FormField--Textarea:focus {
    background-color: rgb(240,240,240);
}

.readOnly.inlineInput .C-FormField--Input:hover,
.readOnly.inlineInput .C-FormField--Input:focus,
.readOnly.inlineInput .C-FormField--Textarea:hover,
.readOnly.inlineInput .C-FormField--Textarea:focus {
    background-color: #ffffff;
}

.readOnly.inlineStory .C-FormField--Input:hover,
.readOnly.inlineStory .C-FormField--Input:focus,
.readOnly.inlineStory .C-FormField--Textarea:hover,
.readOnly.inlineStory .C-FormField--Textarea:focus {
    background-color: rgb(240,240,240);
}
