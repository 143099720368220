.projectTable {
    max-width: 1100px;
    margin: 20px 100px;
}

.projectTableHeader {
    background: transparent !important;
}

.tableHeaderRow {
    background-color: none;
}

.tableBodyRow {
    background-color: white !important;
    margin-bottom: 5px;
}

.tableIcon {
    padding-left: 60px !important;
}

.tableIcon * {
    display: inline-block;
    margin-right: 10px
}

.projectTD, .tableIcon {
    font-size: 14px !important;
    padding: 12px;
}

.projectTH {


    span.C-TableHeader__innerContent {
        font-size: 14px;
        font-weight: 500;
        color: #2b2d31;
    }
}

.modalProjectMessage {
    padding-left: 12px;
  }

.strategicIntent p {
  white-space: pre-wrap;
}
