.alert {
  height: 100%;
  width: 100%;
  margin: auto;
}

.alert__element {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  min-height: 25px;
}

.alert--shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert--transition {
  opacity: 0;
  transition: all 250ms linear;
}

.alert--hidden {
  display: none;
}

.alert__text {
  width: 100%;
  text-align: center;
}

.alert__toggle--shown {
}

.alert__toggle--hidden {
  display: none;
}

.alert--theme-primary {
  background-color: #c5e1ff;
}

.alert--theme-secondary {
  background-color: #dedfe1;
}

.alert--theme-success {
  background-color: #ceead5;
}

.alert--theme-danger {
  background-color: #f9d1d5;
  color: #b85300;

  p {
    color: inherit;
  }
}

.alert--theme-warning {
  background-color: #fff1c6;
}

.alert--theme-info {
  background-color: #cbe9ef;
}

.alert--theme-light {
  background-color: #fefefe;
}

.alert--theme-dark {
  background-color: #d0d2d4;
}
