.dialog-view {
  &-filter {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &-container {
    position: relative;
    overflow: auto;
    margin: auto;
    background-color: #ffffff;
    border-radius: 10px;
    height: 90%;
    width: 90%;
  }
  &-close {
    color: white;
    align-items: center;
    position: absolute !important;
    right: 5%;
    top: 5%;
    z-index: 1001 !important;
    display: flex;
  }
  &-toolbar {
    margin-right: 110px;
    align-self: flex-end;
  }
  &-download {
    float: right;
    margin-right: 5%;
  }

  &-story {
    display: flex;
    flex-wrap: wrap;
  }

  &-cut {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
    margin: 10px;
    padding: 10px;
    border-radius: 9px;

    &--count {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      text-align: center;
      border: 1px solid #000000;
    }
  }

  &-item {
    max-width: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
  }
  &-assethub {
    position: relative;
    top: -25px;
    margin-bottom: 0;
  }
}
