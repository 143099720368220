.larger_modal {
  .C-Modal__container {
    max-width: 80vw;
  }
}

.datepicker_modal {
  .C-Modal__scrollContainer {
    overflow: visible;
  }
}

.modal_first_container {
  display: flex;
  gap: 20px;
}

.modal_checkboxes {
  margin-top: 35px;
  display: inline-flex;
  padding-bottom: 30px;
  padding-left: 15px;
}

.image-title {
  display: flex;
  flex-direction: column;
  height: 98%;
  min-width: 300px;
}

.reference-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: 70%;
  min-width: 300px;
}

.modal_second_container {
  width: 100%;
  border-top: 1px solid #b3bcd4;
  border-bottom: 1px solid #b3bcd4;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  margin-left: -4px;
}

.modal_retail_container {
  display: inline-flex;
  padding-bottom: 10px;
  padding-top: 10px;
}

.modal-text-area {
  width: 93%;
  margin-top: 15px;
}

.modal-vendor-container {
  width: 25%;
  margin-top: 20px;
}

.modal-final-container {
  width: 50%;
  margin-top: 20px;
}

.modal-range-div {
    display: flex;
    gap: 25px;
}

.modal-range {
    margin-top: 15px; 
    width: 47%;
}

.modal-size {
    margin-top: 15px;
    width: 49%;
}

.modal-height {
    display: inline-flex;
    margin-top: 13px;
}

.modal-depth {
    display: inline-flex;
    margin-top: 9px; 
}

.vendor-email-div {
  width: 100%;
  margin-top: 25px;
  display: inline-flex;
}

.image-button {
  margin-bottom: 100;
}

.art-design {
  display: inline-flex;
  margin-top: 15px;
  margin-left: 15px;
}

.product_design {
  display: inline-flex;
  width: 100%;
  margin-top: 15px;
  margin-left: 15px;
}

.final_image {
  display: block;
  margin-left: 10px;
  width: 22%;
}