.timeline-item {
  width: 100%;
  flex-direction: column;

  &--date {
    margin-top: 12px;
  }

  &--content {
    display: flex;
  }

  &--title {
    text-transform: uppercase;
  }

  &--value {
    font-weight: 100;
    margin-right: 5px;
    text-transform: initial;
  }
}

.notes_info {
  font-size: 11px;
  color: #888888;
}

.pyramid-and-division-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.timeline-item--value:hover {
  background: #d6d6d6 !important;
}
tr:hover .main-container {
  background: #d6d6d6 !important;
}
.timeline-approved {
  position: absolute;
  right: 0;
  top: 6px;
  width: unset;
}

.timeline-spinner {
  position: absolute;
  right: 8px;
  top: 12px;
}

.datepicker-icon div span {
  top: 6px;
}

.autocomplete-alignment {
  label,
  p {
    margin-bottom: 0px;
  }
}

.C-Timeline__Item__Bullet {
  background: #008300;
  border-color: #008300;
  border-radius: 24px;
  border-radius: var(--canvas-border-radius--round);
  height: 16px;
  margin: 17px 12px 12px 0;
  min-width: 16px;
  transition: canvas-transition(background border-color);
  /* z-index: 2; */
  z-index: var(--canvas-z-index--element);
}

.C-Timeline__Item__Content {
  margin-top: 0px;
  width: 100%;
}

.icons {
  padding: 10px;
  text-align: left;
  position: relative;
}

.main-container {
  background-color: inherit;
}

.notes_info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.C-MenuButton {
  padding-left: 7px;
  padding-right: 7px;
}

.edit-textbox {
  padding: 7px;
  border-radius: 8px;
  margin-bottom: 6px;
  margin-right: 5px;
  border: 1px solid var(--functional-border-border-color-contrast, #d3d5d8);
}

.C-EditButton {
  padding: 1px 7px;
}

.marginLeft {
  margin-left: 5px;
}

.C_Approved{
  color: black !important;
  font-weight: bold;
}
.C_NotApproved{
  color: red !important;
  font-weight: bold;
}
