.creativeSummaryPage {
  margin: 8px;
}

.umbrella-container {
  // background-color: rgb(45, 126, 126);
  background-color: lightgray;
  padding: 15px;
  border-radius: 8px;
  margin: 10px 0px !important;
}

.offer-container {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  border: 0.5px solid gray;
  margin-top: 5px;
}

.back-button {
  padding-left: 10px !important;
}

.back-button-grid {
  padding-top: 20px !important;
}

.creative-header {
  padding-top: 15px !important;
  text-align: center;
}

.pdf-download {
  text-align: right;
  float: right;
}

.summary-item {
  // width: 100%;
  // flex-direction: column;

  // &--date {
  //     margin-top: 12px;
  // }

  // &--content {
  //     display: flex;
  // }

  // &--title {
  //     text-transform: uppercase;
  // }

  &--value {
    font-weight: 100;
    margin-right: 5px;
    text-transform: initial;
  }
}

.creativeSummaryPage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-container {
  padding: 5px;
  grid-gap: 5px;

  border: 0.5px solid gray;
}

.image-content-details {
  padding: 5px;

  border: 0.5px solid gray;
}

.image-card {
  //border: 1px solid gray;

  margin-top: 5px !important;
}

.marketing-story-title {
  border-bottom: 1px solid gray;
  margin-bottom: 5px;
  padding: 5px;
}

.no-items-found {
  text-align: center;
}

.loadingSpinner {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.deactivated {
  text-decoration: line-through;
  color: rgb(43, 45, 49, 0.5);
}
