.item-container {
  padding: none !important;
  display: flex;
}

.incompleteItem {
  background-color: rgb(254, 250, 207) !important;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.itemToggle {
  padding: 5px 0px 0px 10px !important;
}

.itemImage {
  border: 0px solid #b3bcd4;
  margin: 10px 0px 10px 0px !important;
}

.publishing-item-image {
  border: 0px solid #b3bcd4;
  margin: 0px 0px 10px 0px !important;
}

.item-priority-indicator {
  float: right;
  padding-right: 6px;
  color: black !important;
  pointer-events: all;
  background-color: transparent !important;
  border: none !important;
}

.itemGrid {
  padding-bottom: 10px;
  padding-right: 5px;
}

.itemDetails {
  font-size: 12px;
}

.itemTitle {
  font-size: 12px;
  padding: 6px;
}

.offerTitle {
  font-size: 12px;
  padding-top: 5px;
  padding-right: 5px;
}

.itemAssethubDownload {
  font-size: 13px;
  padding-right: 3px;
}

.item-expander.rotate-counter-clockwise-45:hover,
.item-expander.rotate-clockwise-45:hover {
  background-color: #d6d6d6;
  border-radius: 50%;
  transition: all 0.25s ease;
}

.C-Select,
.C-Input,
.C-Input__Parent {
  background-color: white !important;
  color: #666 !important;
}

.C-Input.isDisabled {
  background-color: #ebebeb !important;
}

.C-Input--type-label {
  color: #666 !important;
}

.C-FormField__Label__itemTypeRequired {
  color: #b85300;
  font-size: 10px;
  /* line-height: 7px; */
}

.ReactModal__Body--open [role='tooltip'] {
  z-index: 1050 !important;
}

mark {
  background-color: #f1eb73;
}

.item-expander {
  user-select: text;
  -webkit-user-select: text; 
  -moz-user-select: text; 
  -ms-user-select: text; 
}
