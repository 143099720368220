.errorEmailMessage {
    color: #cc0000;
    margin: 15px 0 0 4px;
    text-align: left;
}

.errorEmailList {
    color: #cc0000;
    list-style-type: none;
    text-align: left;
}