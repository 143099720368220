.pyramidDetails {
  margin: 0 auto !important;
  padding: 5px 10px;
  width: 100% !important;

  .C-GridItem {
    padding: 6px 6px 2px 6px !important;
  }
}

.progress-bar {
  margin: 10px auto !important;
  width: 95% !important;
}

.storyContainer {
  background-color: #ffffff;
  padding: 10px 10px;
  margin: 0 auto 10px auto !important;
  width: 95% !important;
  border-radius: 10px;
  border-top: 2px solid rgb(228, 228, 228);
  border-bottom: 2px solid rgb(228, 228, 228);

  .C-GridItem {
    padding: 2px 6px 2px 6px !important;
  }
}

.editPyramidButtons {
  float: right !important;

  .red-btn,
  .red-btn:hover {
    background-color: #b92c09;
    border: none !important;
    color: white;
    span {
      margin-left: 10px;
    }
  }

  .dkgrey-btn,
  .dkgrey-btn:hover {
    background-color: #666666;
    border: none !important;
    color: white;
    span {
      margin-left: 10px;
    }
  }

  .ltgrey-btn,
  .ltgrey-btn:hover {
    background-color: #dddddd;
    border: none !important;
    color: black;
    span {
      margin-left: 10px;
    }
  }
}

.rotate-clockwise-45 {
  transform: rotate(00deg);
  transition: transform 0.2s;
}

.rotate-counter-clockwise-45 {
  transform: rotate(-90deg);
  transition: transform 0.2s;
}