.labelledIcon {
  padding: 8px 10px 2px 15px;
}

.category-icon {
  padding: 12px 8px 8px 8px;
}

.wa-mu-category-icon {
  padding: 12px 8px 8px 8px;
  font-size: 25px;
  color:#3838a3 ;
}

.labelled-loading {
  position: relative;
  right: 5px;
  top: -4px;
}

.fa-disabled {
  padding: 8px 10px 10px 15px;
  opacity: 0.6;
  cursor: not-allowed;
}
.wa-mu-fa-disabled {
  padding: 8px 10px 10px 15px;
  opacity: 0.6;
  font-size: 25px;
  cursor: not-allowed;
}
.linked-offers-header {
  height: 40px;
}

.linked-offers-text {
  margin-left: 3px;
  font-size: 14px;
  font-weight: 700;
  float: left;
  padding-top: 4px;
  padding-left: 8px;
}

.show-all-items-button {
  float: right;
  height: 10px !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.publishing-show-all-items-button {
  float: right;
  margin-right: 8px;
}

.media-unit-date {
  padding: 0px !important;
  font-size: 13px;
  display: grid;
  align-items: center;
}

.audio-chip-assigned {
  margin-top: 6px !important;
  margin-left: 0px !important;
  color: #519b80 !important;
  border: 1px solid #519b80 !important;
  background-color: #f0f0f0 !important;
}

.audio-chip-unassigned {
  margin-top: 6px !important;
  margin-left: 0px !important;
  color: #888888 !important;
  border: 1px solid #888888 !important;
  background-color: #f0f0f0 !important;
}

.volume-up-icon {
  margin-right: 3px;
}

.media-unit-name-grid-item {
  .C-GridContainer {
    flex-wrap: none !important;
  }
}

.media-unit-name-input {
  float: left;
  width: 98%;

  input {
    border: 0px solid #d6d6d6;
    box-shadow: none !important;
    background-color: #e6e6e6;
    font-size: 16px;
    font-weight: 500;
  }

  input:hover {
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }

  .C-FormField--Input {
    border: 0px solid #d6d6d6;
    box-shadow: none !important;
    background-color: #e6e6e6;

    font-size: 16px;
    font-weight: 500;
  }

  .C-FormField__FieldInfo {
    margin-top: 0px !important;
  }

  .C-FormField--Input::placeholder {
    color: #cc0000 !important;
    opacity: 0.5;
  }

  .C-FormField--Textarea {
    min-height: 14px;
    max-height: 100px;
    margin: 5px;
  }

  .C-FormField--Input:hover {
    background-color: #3e72db;
  }

  .C-FormField--Input:focus,
  .C-FormField--Textarea:hover,
  .C-FormField--Textarea:focus {
    box-shadow: none !important;
    background-color: #f5f5f5;
  }
}

span.C-Checkbox__Box {
  background: #fff !important;
  border: 1px solid #888;
  border-radius: 4px;
  box-shadow: none;
  display: flex;
  height: 20px;
  margin-right: 9px;
  min-width: 20px;
  position: relative;
  transition:
    border 0.3s ease,
    background 0.3s ease,
    box-shadow 0.3s ease;
  width: 20px;
  z-index: auto;
}

.C-Checkbox__Input:checked + .C-Checkbox__Box {
  background: #3e72db !important;
  border: 1px solid #3e72db;
}

.span.C-Checkbox__Box:disabled:checked {
  background: #888 !important;
  border: 1px solid #d6d6d6;
}

.C-Checkbox__Input:checked:disabled + .C-Checkbox__Box .enterprise-icon {
  opacity: 1;
  background: #888 !important;
  border: 1px solid #d6d6d6;
}

.category-name {
  padding-left: 10px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-unit-icon {
  padding: 12px 10px 10px 0px !important;
  display: flex;
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
}

.spinner-expander {
  padding: 5px;
}

.category-expander {
  padding: 5px;
}

.rotate-clockwise-45 {
  transform: rotate(00deg);
  transition: transform 0.2s;
}

.rotate-counter-clockwise-45 {
  transform: rotate(-90deg);
  transition: transform 0.2s;
}

.hidden-bucket {
  display: none;
}

.primary-header-container {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.primary-header-text {
  font-weight: 700;
  font-size: 16px;
}

.secondary-header-text {
  font-weight: 700;
  font-size: 14px;
}

.visible-bucket {
  margin: 10px 0 20px 0 !important;
  border-radius: 10px;
  background-color: white;
  padding-bottom: 6px;
}

.visible-bucket.category-expanded {
  margin: 10px 0 20px 0 !important;
  border-radius: 10px;
  background-color: #c9d9f8 !important;
  min-height: 100%;
}

.messaging-details-container {
  padding: 12px;
}

.media-unit-container {
  background-color: #e6e7ea;
  padding: 12px;
  border-radius: 4px;
  margin: 10px;
}

.media-unit-container:after {
  content: '';
  display: table;
  clear: both;
}
.media-unit-container-content {
  float: left;
  padding: 10px;
  width: 70%;
}
.weeklyad-media-unit-container-content {
  float: left;
  padding: 10px;
  width: 62%;
}

.media-unit-container-flyout {
  float: left;
  background-color: white;
  width: 30%;
}
.wa-mu-container-flyout {
  float: left;
  background-color: white;
  width: 38%;
  display: block;
  overflow-y: scroll;
}

.category-container {
  //margin-top: 35px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(80vh - 70px);
}

.category-title-container {
  display: flex;
  align-items: center;
  color: #333333;
}

.pdf-icon-adjustment {
  padding: 0px 8px 0px 8px !important;
}

.category-icons {
  color: rgb(44, 44, 211);
  padding-right: 17px !important;
}

.category-details {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.category-details-container {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  color: #333333;
  font-size: 14px;
}

.category-filter {
  background-color: #f0f0f0;
  z-index: 1;
  padding: 10px 10px 0px 10px;
  border-radius: 5px;
}

.category-details-header {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 25px;
  color: white;
  background-color: #333333;
  font-weight: 700;
  padding: 4px !important;
}

.category-details {
  height: 95px;
  border: 1px solid #e6e6e6;
  padding: 4px !important;
  background-color: white;
}

// .category-details-first {
// }

.category-details-second {
  color: #3e72db;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category-details-third {
  display: flex;
  align-items: center;
}

.category-details-forth {
  display: flex;
  align-items: center;
}

.category-details-featured {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3e72db;
  // background-color: #e6e6e6;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 2px;
}

.media-unit-section-container {
  padding: 0px;
  margin-bottom: 12px;
}

.media-unit-row-container {
  padding: 10px 10px 10px 10px !important;
  color: #333333;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 18px;
}

.details-header {
  background-color: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 12px;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.C-Rating .C-Rating__Input:checked + .C-Rating__Icon {
  color: var(--canvas-icon-color--alert) !important;
}

.item-media-unit-row-container {
  padding: 0px !important;
  color: #333333;
  background-color: white;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
}

.media-unit-pane-item-list {
  background-color: #f7f7f7;
  padding: 12px;
  flex: 1;
  min-width: 375px;
  height: fit-content;
}

.close-item-flyout {
  float: right;
  padding-right: 4%;
  background-color: white !important;
  box-shadow: none !important;
  border: none !important;
}

.media-unit-item-title {
  font-size: large;
  font-weight: bold;
  color: #cc0000;
}

.no-pointer {
  pointer-events: none;
}
.media-unit-contact {
  background-color: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 12px;
  font-weight: 600;
  font-size: 16px;
  border-top: 0.5px solid gray;
  border-left: 0.5px solid gray;
  border-right: 0.5px solid gray;
}

.media-unit-container-rows {
  padding: 0px !important;
}

.media-unit-image-preview {
  background-color: lightgray;
  padding: 0px !important;
  height: auto !important;
  text-align: center;
}

.media-unit-contact-photo-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 1400px;
  height: 100%;
  border-top: 0.5px solid gray;
  border-right: 0.5px solid gray;
  border-bottom: 0.5px solid gray;
}

.media-unit-image {
  aspect-ratio: 1/1;
  width: 100%;
  padding: 12px 12px 6px 12px;
  max-height: 188px;
  max-width: 188px;
}

.media-unit-image-text {
  font-size: 12px;
  margin-top: 0px;
}

.no-audio-filler {
  height: 37px;
}

.media-unit-offer-expand-icon {
  margin-right: 8px;
  margin-top: 3px;
  float: left;
}

.media-unit-expand-icon {
  margin-top: 8px;
  margin-right: 10px;
  float: left;
}

.media-unit-offer-expand-icon {
  display: flex;
  align-self: baseline;
}

.media-unit-button-container {
  padding-top: 12px !important;
}

.media-unit-button-edit {
  border-radius: 8px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.media-unit-button-cancel {
  border-radius: 8px !important;
  border: 1px solid #3e72db !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.media-unit-button-save {
  color: #fff !important;
  background-color: #3e72db !important;
  border-radius: 8px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mu-ellipsis-dropdown {
  width: 110px !important;
  right: -0px !important;
  /* margin: 0px !important; */
  padding: 0px !important;
  top: 36px;
}

media-unit-button-elipse {
  margin-left: 6px !important;
}

.C-Input.isDisabled {
  background-color: #f0f0f0 !important;
}

.category-container-row {
  margin: 0px !important;
  width: auto !important;
  
  .C-GridContainer {
    width: 100% !important;
  }

  .C-FormField.inlineInput {
    padding: 0px;
  }
}

.checkbox-media-channels {
  margin-right: 8px;
}

.checkbox-input {
  padding: 5px !important;
  display: flex;
  align-items: left;
  background-color: white;

  select.C-Input.C-Select {
    margin-top: 5px;
  }

  .C-Autocomplete {
    margin-bottom: 5px;
  }
}

.category-label {
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  border-bottom: 1px solid #f0f0f0;
}

.category-input {
  padding: 5px !important;
  display: inline-block;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;

  select.C-Input.C-Select {
    margin-top: 5px;
  }

  .C-Autocomplete {
    margin-bottom: 5px;
  }
}

.category-input-cb {
  padding: 5px !important;
  padding-right: 15px !important;
  display: flex;
  align-items: center;
  // justify-content: center;
  background-color: white;
  border: 0.5px solid gray;
  border-left: none;
}

.center-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-text {
  text-align: center;
}

.media-unit-market-edit {
  padding-left: 10px;
}

.media-unit-count {
  margin: 0 0 0 10px;
  color: #999;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
  white-space: nowrap;
}

.media-unit-card {
  box-shadow: none !important;
}

.promo-message-umbrella {
  border-radius: 4px;
  color: #000;
}

.notes-for-creative {
  background-color: #fff;
  width: 100% !important;
  color: #000;
  border-top: 1px solid #e6e6e6;
}

p.C-Input--type-info.isDisabled {
  display: contents !important;
}

p.error-text {
  color: orangered;
  font-size: 12px;
}
.padding-right-10 {
  padding-right: 10px !important;
}

.media-unit-init-form{
  width: 100%;
}

.media-unit-init-form td{
  padding: 6px 12px;
  border-bottom: 1px solid #d6d6d6;
}

.media-unit-init-form .media-unit-row-header{
  background: #f0f0f0;
  max-width: 90px;
  font-weight: bold;
  font-size: 13px;
}

.category-title{
  padding: 6px 12px ;
  .category-title-input{
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    font-weight: 100;
    font-size: 14px;
  }
}

.message-settings-grid-items {
  margin-top: 10px;
}

.bold-header {
  font-weight: bold;
  margin-bottom: 10px;
}

hr.modal-divder {
  margin-top: 30px;
  margin-bottom: 20px;
  border: .5px solid #EEE;
}

.modal-button  {
  margin-top: 60px;
  margin-bottom: 10px;
}

.media-unit-column-a{
  padding: 6px 12px ;
  .media-unit-name-input{
    border: 2px solid #d6d6d6;
    border-radius: 10px;
  }
}

.bcm-category-key-container {
  border: 1px solid #b3b6bc;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
}

.bcm-details-header {
  background-color: #F0F0F0;
  justify-content: center;
  font-weight: 700;
}

.underline-text {
  text-decoration: underline;
}

.bcm-details-list > ui {
  margin-bottom: '30px';
  margin-top: '10px'
}

.bcm-details-list > li {
  margin-top: 2px;
  margin-bottom: 2px;
}
.message_settings p.C-Input--type-info{
  display: block !important;
  margin-bottom: 10px !important;
  color: #666666
}

.category-dropdown{
  height: max-height !important;
}

.bcm-table-rows{
  justify-content: center;
}
.title-message{
  font-size: 16px;
}

.right-align {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}
.sticky-header {
  position: sticky;
  top: 50px; 
  z-index: 2; 
  background-color: #c9d9f8; 
}