.tabs-container {
  padding: 0px 15px 15px;
  flex-grow: 1;
  overflow: auto;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.image {
  height: 400px;
  padding: 0 0 0 0;
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.4);
}
.hidden {
  opacity: 0;
}
.image-spinner {
  position: absolute;
  transform: translate(-50%, -50%);
}
#assethub_link,
#page_image_link {
  border: 2px solid black;
  margin-bottom: 20px;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  &:focus {
    outline: none;
    border: 2px solid black;
    box-shadow: none;
    border-radius: 10px;
  }
  &:hover {
    outline: none;
    border: 2px solid black;
    box-shadow: none;
    border-radius: 10px;
  }
  &:disabled {
    outline: none;
    border: 2px solid black;
    box-shadow: none;
    border-radius: 10px;
  }
}

.details-tabs {
  background-color: white;
  position: sticky;
  top: 0px;
  z-index: 3;

  & .C-Tabs__Item {
    padding: 10px 0px;
  }
}

.centered-captains {
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 40px;
}
.book-captains {
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 40px;
}

.additionalInfo {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.infoHeader {
  align-items: 'center';
  background-color: '#F2F3F3';
  display: 'flex';
  height: 50;
  justify-content: 'space-between';
}
.page-title-label {
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
}
#editable-page-title {
  font-size: 16px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 2px solid #becdf3;
  outline: none;
  box-shadow: none;
  width: 80%;
  padding-left: 20px;
  font-weight: bold;
  color: #000000;
}

.editable-input-container {
  position: relative;
  align-items: center;
  width: 100%;
}
