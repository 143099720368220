.comment {
  &-container {
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px #e2e2e2;
  }

  &-content {
    width: 100%;
    padding-right: 15px;
  }

  &-header {
    display: flex;
    justify-content: space-between;

    &__creator {
      font-weight: bold;
    }

    &__date {
      color: #6c6c6c;
    }
  }

  &-text {
    margin: 0;
  }

  &-actions {
    display: flex;
    align-items: center;
  }
}
