.C-NavigationGroup__ContentContainer > .C-SideNav__NavigationItem {
  padding-left: 38px;
}

.sublink {
  font-weight: 200;
}

.weeklyAdSubLink,
.catalogSubLink {
  background-color: #ddd;
  font-weight: 200;
}

.weeklyAdSubLink.isSelected,
.catalogSubLink.isSelected {
  border-left: none !important;
  padding-left: 40px;
  font-weight: 600;
}

.weeklyAdLink h6,
.catalogLink h6 {
  color: white;
}

.weeklyAdLink + .C-Button.\--icon [data-icon],
.catalogLink + .C-Button.\--icon [data-icon] {
  color: white;
}

.weeklyAdSubLink.isSelected.active,
.weeklyAdLink {
  background-color: #6e23b3 !important;
  color: white !important;
}

.catalogSubLink.isSelected,
.catalogLink {
  background-color: #3d70d6 !important;
  color: white !important;
  font-weight: 400 !important;
}

a.C-SideNav__NavigationItem.sublink.active ,
a.C-SideNav__NavigationItem.catalogSubLink.active,
.C-SideNav__NavigationItem.weeklyAdSubLink.active{
  background-color: #ddd;
  font-weight: 200;
  color: #2b2d31;
}

.navHeader {
  color: #3838a3;
  padding-left: 15px;
  border-bottom: 1px solid #3838a3;
}

.logo {
  display: block;
  text-align: center;
}

.logo img {
  max-width: 80%;
  height: auto;
}

.C-SlackIcon {
  margin-left: 85px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.C-SideNav {
  overflow-y: scroll;
  direction: rtl;
}

span, .hc-pa-normal, .navbar-footer {
  direction: ltr;
}
