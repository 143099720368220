.button-container {
  width: 100%;
  text-align: center;
  position: relative;
  bottom: 0;
  margin-top: 20px;
}

.full-screen-card {
  width: 100%;
  min-height: 90vh;
  position: relative;
}

.bottom-buttons {
  margin: 25px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.apply-to-all-views-label {
  color: rgb(101, 101, 101);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.right-aligned-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.copy-button {
  color: #3838a3;
  margin: 4px;
}

#apply-to-all-views-button {
  background-color: transparent;
  &:hover,
  &:active {
    .apply-to-all-views-label {
      text-decoration: underline;
    }
  }
}
