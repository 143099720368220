.hiddenBucket {
  display: none;
}

.visibleBucket {
  margin: 10px 0 20px 0 !important;
  border-radius: 10px;  
  padding: 5px;
}

.bucketOfferItem {
  background: #ffffff !important;
  padding: 4px;
  margin: 6px;
  border-radius: 10px;
  border: 1px solid #b3bcd4;
}

.bucketItem {
  background: #ffffff !important;
  padding: 4px;
  margin: 6px;
  border-radius: 10px;
  border: 1px solid #b3bcd4;
}

.expandStoryIcon {
  display: flex;
  align-items: center;
}

.trashIcon {
  text-align: right;
}

.storyHeader {
  color: #000;
}

.labelledIcon {
  padding: 8px 10px 10px 15px;
}

.clickable {
  cursor: pointer;
}

.modalPyramidMessage {
  padding-left: 12px;
}

.linkedUmbrellaContainer {
  padding: 15px 20px 20px 20px;
  grid-gap: 6px;

  .umbrellaCard {
    box-shadow: 0px 0px 0px rgba(205, 205, 205, .5);
    background-color: #d6d6d6 !important;
  }
}

.pyramid-title-container {
  display: flex;
  align-items: center;
}

.pyramid-expander {
  padding: 5px;
}

.pyramidListContainer{
  display: flex;
  align-items: center;
}

.download-pdf-btn {
  position: fixed;
  right: 100px;
  top:120px;
  z-index: 100000;
}

input#inlineEditInput::placeholder {
  color: rgba(251, 44, 8, 0.968);
}