.stickybar-container-project {
    position: sticky;
    top: 0;
    margin-top: 0 !important;
    z-index: 3;

    display: grid;
    grid-column: 3fr 1fr 3fr 1fr;


    .dkgrey-btn, .dkgrey-btn:hover {
        background-color: #666666;
        border: none !important;
        color: white;
        span {
            margin-left: 10px;
        }
        margin-top: 17px;
    }

    #pyramidFilter {
        width: 38%;
    }
}