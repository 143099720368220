.createEditDrawer {
  overflow-y: scroll !important;
}

.createEditDrawer .C-GridContainer:first-of-type .C-GridItem .C-Container {
    background-color: #B92C09;
}

.createEditDrawer .C-GridContainer:first-of-type .C-GridItem .C-Container .C-GridContainer .C-GridItem .C-Heading:first-of-type {
    font-weight: normal;
    color: #F0F0F0;
    margin-left: 10px !important;
}

.form { 
   padding: 30px;
}

.redButton {
    margin-top: 16px;
    background-color: #B92C09 !important;
    color: #F0F0F0 !important;
}

.redButton * {
    margin: 0 4px;
}

.redButton:disabled {
    background-color: #f0f0f0 !important;
    color: #888888 !important;
}

.C-DatePicker__NavButton * {
    color: black;
}

.C-DatePicker__Card { 
    width: auto;
} 

.C-DatePicker__DateButton.C-Button.isSelected {
    background-color: #B92C09 !important;
}

.createEditProjectCard {
    background-color: #d9d9d9 !important;
}
                      
                    