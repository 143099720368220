.projectHeadingExecution {
  margin-top: 10px !important;
}

.merch-story-list-progress {
  margin: 20px !important;
  max-width: 97%;
}

.selectedProject {
  margin-top: 10px;
  margin-bottom: 10px;
}

.story-header {
  font-weight: 900;
}
.stories-umbrellas-container {
  display: flex;
  padding: 12px 12px 48px 12px;
  align-items: flex-start;
}

.stories-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background: #ffffff;
  padding-top: 12px;
  border-radius: 8px;
}

.story-item-container {
  border-radius: 8px;
  border: 1px solid var(--functional-border-border-color-default-hover, #b3b6bc);
  background: var(--functional-background-background-color-input, #fff);

  padding: 12px;
  align-items: flex-start;
  margin-top: 5px;
  align-self: stretch;
  font-size: 12px;
  cursor: pointer;
}

.selected-story-item-container {
  padding: 12px;
  align-items: flex-start;
  font-size: 12px;
  align-self: stretch;
  background: var(
    --functional-background-background-color-interactive,
    #3d70d6
  );
  color: #ffffff;
}

.story-name-containter {
  display: flex;
  align-items: flex-start;
  padding-bottom: 18px !important;
}

.umbrellas-divider {
  border-top: #b3b6bc 1px solid;
  margin: 0px 0px 15px 0px;
}

.umbrellas-header-container {
  display: flex;
}

.umbrellas-date-icon-container {
  display: flex;
  align-items: center;
}

.umbrellas-container {
  display: flex;
  padding: 12px;
  background: var(--canvas-background-color--app);
}

.umbrella-item-container {
  background-color: #f2f2f2;
  border: 0px !important;
  cursor: pointer;
}

.expand-row-transition {
  transition: all 0.3s ease-in-out;
}

.row-height-expand {
  transform: scale(1, 1);
  line-height: 100%;
  height: auto;
  visibility: visible;
  overflow: auto;
}

.row-height-collapse {
  transform: scale(1, 0);
  line-height: 0px;
  visibility: collapse;
  height: 0;
}

.grid-cell {
  outline: 1px solid;
  padding: 0.5em;
  margin-top: 1px;
  margin-left: 1px;
  height: 100%;
}

.umbrella-offers-title {
  display: flex;
  justify-content: space-between;
}

td.C-TableData.--xs-size-7.projectTD {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.execution-note {
  padding: 10px;
}

.date-input {
  margin-left: 5px;
}

.week-of-final-due-date {
  color: red !important;
  font-weight: bolder !important;
}

.kickoff-info-past-due {
  color: #cc0000;
  font-weight: bold;
}

.info-container {
  display: flex;
  z-index: 1;
}

.info-container-header {
  display: flex;
  flex: 1;
  z-index: 1;
}

.info-container-date {
  display: flex;
  flex: 0.75;
  align-items: center;
}

p.imageTrackingList {
  margin-bottom: 0;
}

p.projectSummaryList {
  margin-bottom: 15px !important;
}
