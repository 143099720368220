.hiddenBucket {
  display: none;
}

.visibleBucket {
  margin: 10px 0 20px 0 !important;
  border-radius: 10px;
}

.bucketItem {
  background-color: white;
  padding: 4px;
  margin: 6px;
  border-radius: 10px;
  border: 1px solid #b3bcd4;
}

.trashIcon {
  text-align: right;
}

.storyHeader {
  color: #000;
}

.labelledIcon {
  padding: 8px 10px 10px 15px;
}

.modalPyramidMessage {
  padding-left: 12px;
}

.linkedUmbrellaContainer {
  padding: 15px 20px 20px 20px;
  grid-gap: 6px;

  .umbrellaCard {
    box-shadow: 0px 0px 0px rgba(205, 205, 205, 0.5);
    background-color: #eb6363 !important;
  }
}
